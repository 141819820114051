import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Alt from "./pages/Alt";
import Form from "./pages/Form";
import Feed from "./pages/Feed";
import Verified from "./pages/Verified";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/alternatives" element={<Alt />} />
      <Route path="/boycott-form" element={<Form />} />
      <Route path="/feed" element={<Feed />} />
      <Route path="/verified" element={<Verified />} />
    </Routes>
  );
}
