import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ReactComponent as Country } from "../assets/icons/Country.svg";

export default function LanguageMenu() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex space-x-2 border border-primary-green px-3 py-1 rounded-lg bg-white">
            <Country className="my-auto" />
            <div className="font-CairoMedium mx-1">
              {i18n.language === "ar" ? t("arabic") : t("english")}
            </div>
            <ChevronDownIcon className="h-5 w-5 my-auto" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {() => (
                  <button
                    className={`${
                      i18n.language === "ar"
                        ? "bg-primary-green text-white"
                        : "text-gray-900 hover:bg-gray-100"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm font-CairoMedium`}
                    onClick={() => {
                      i18n.changeLanguage("ar");
                    }}
                  >
                    {t("arabic")}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {() => (
                  <button
                    className={`${
                      i18n.language === "en-US"
                        ? "bg-primary-green text-white"
                        : "text-gray-900 hover:bg-gray-100"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm font-CairoMedium`}
                    onClick={() => {
                      i18n.changeLanguage("en-US");
                    }}
                  >
                    {t("english")}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
