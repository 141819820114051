import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import BannerImg from "../assets/images/Banner.png";
import BoycottImg from "../assets/images/HomeBg.png";
import { ReactComponent as Qate3Icon } from "../assets/icons/Qate3.svg";
import FloatingButton from "./FloatBtn";
import ShareModal from "./ShareModal";
import LanguageMenu from "./LanguageMenu";

export default function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigationItems = [
    {
      name: "Feed",
      name_ar: "شارك رأيك",
      href: "/feed",
    },
    {
      name: "Search Brands",
      name_ar: "المنتجات",
      href: "/",
    },
  ];

  return (
    <main>
      <div className="bg-gray-50 py-5 border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 items-center justify-between">
            <div onClick={() => navigate("/")}>
              <h3 className="font-bold text-2xl cursor-pointer">
                Free
                <label className="cursor-pointer text-transparent bg-clip-text bg-gradient-to-b from-primary-red to-primary-green">
                  Palestine
                </label>
              </h3>
            </div>
            <div className="flex space-x-7">
              {navigationItems.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      navigate(item.href);
                    }}
                    className={`font-CairoMedium hover:underline hover:text-primary-green my-auto cursor-pointer ${
                      item.href === location.pathname
                        ? "text-primary-green "
                        : ""
                    }`}
                  >
                    {i18n.language === "ar" ? item.name_ar : item.name}
                  </div>
                );
              })}
              <LanguageMenu />
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img
          className="h-40 lg:h-72 w-full object-cover mx-auto"
          src={BoycottImg}
          alt="free palestine"
        />
        <div className="absolute top-0 z-10 w-full mt-1 lg:mt-3">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {location.pathname === "/" ? null : (
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="flex"
              >
                <ChevronLeftIcon className="h-5 w-5 my-auto mx-1 text-white" />
                <label className="font-CairoMedium text-white cursor-pointer underline">
                  {t("back")}
                </label>
              </div>
            )}
          </div>
        </div>

        <h2 className="absolute inset-0  text-gray-200 mx-2 font-bold">
          <div className="flex justify-center items-center h-full text-center">
            <div>
              <Qate3Icon className="h-14 w-32 lg:w-52 mb-3 lg:mb-8 mx-auto" />

              <h2 className="text-lg lg:text-4xl">
                ( مَنْ قَتَلَ نَفْسًا بِغَيْرِ نَفْسٍ أَوْ فَسَادٍ فِي الْأَرْضِ
                فَكَأَنَّمَا قَتَلَ النَّاسَ جَمِيعًا وَمَنْ أَحْيَاهَا
                فَكَأَنَّمَا أَحْيَا النَّاسَ جَمِيعًا )
              </h2>
            </div>
          </div>
        </h2>
      </div>

      <div className="py-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* <div dir="rtl" className="text-center">
            يرجى ملاحظة أن هذه القائمة ليست كاملة، ونحن نضيف المزيد من العلامات
            التجارية والشركات إلى القائمة ونحن نتحقق من تورطها في الاحتلال
            الإسرائيلي لفلسطين.
          </div> */}
          {/* Replace with your content */}
          {children}
          {/* /End replace */}
          <img
            src={BannerImg}
            alt="boycott"
            className="h-full w-full object-cover mt-5 lg:mt-20 cursor-pointer"
            onClick={() => navigate("/boycott-form")}
          />
        </div>
      </div>

      <FloatingButton open={open} setOpen={setOpen} />

      <ShareModal
        open={open}
        setOpen={setOpen}
        shareDetails={{ title: "ساهم في حملة المقاطعة من خلال هذا الموقع" }}
        theme={{
          backgroundColor: "bg-white",
          secondaryBackgroundColor: "bg-gray-100",
          textColor: "text-black",
          borderColor: "border-gray-300",
        }}
      />
    </main>
  );
}
