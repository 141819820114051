export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

//mongodb search
export const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
export const REALM_API_KEY = process.env.REACT_APP_REALM_API_KEY;

//GA key
export const GA_KEY = process.env.REACT_APP_GA_KEY;
