import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ReactPaginate from "react-paginate";
import Layout from "../components/Layout";
import { BASE_URL, REALM_APP_ID } from "../config/constants";
import Loader from "../components/Loader";
import * as Realm from "realm-web";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [phrase, setPhrase] = useState("");
  const [activePage, setactivePage] = useState(0);
  const [total, settotal] = useState(0);
  const [searchCounter, setsearchCounter] = useState(0);
  const [visitCounter, setvisitCounter] = useState(0);
  const [isEmptySearchResult, setisEmptySearchResult] = useState(false);
  const limit = 40;

  // // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setactivePage(event.selected);
  };

  function get_all_boycott_data() {
    axios
      .get(`${BASE_URL}/sub_companies?page=${activePage}&limit=${limit}`)
      .then((res) => {
        setData(res.data.brands);
        settotal(res.data.count);
        setsearchCounter(res.data.search_counter);
        setvisitCounter(res.data.visits_counter);
      })
      .catch((err) => console.log(err));
  }

  async function search_data() {
    const app = new Realm.App({ id: REALM_APP_ID });

    try {
      const credentials = Realm.Credentials.apiKey(
        process.env.REACT_APP_REALM_API_KEY
      );
      const user = await app.logIn(credentials);
      const searchProducts = await user.functions.searchSubCompanies(phrase);
      if (searchProducts.length > 0) {
        setData(searchProducts);
        settotal(searchProducts.length);
        setisEmptySearchResult(false);
      } else {
        setisEmptySearchResult(true);
      }

      axios
        .get(`${BASE_URL}/products/search?phrase=${phrase}`)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    get_all_boycott_data();
  }, [activePage]);

  return (
    <Layout>
      <div className="max-w-7xl justify-center mx-auto p-5">
        {/* SEARCH INPUT */}
        <div className="relative">
          <div
            className={`flex flex-col  ${
              i18n.language === "ar" ? "lg:flex-row-reverse" : "lg:flex-row"
            } space-y-2 lg:space-y-0 lg:space-x-0`}
          >
            <div
              className={`relative rounded-md border border-gray-300 text-gray-900 h-full w-full mx-0 ${
                i18n.language === "ar" ? "lg:ml-2" : "lg:mr-2"
              }`}
            >
              <MagnifyingGlassIcon
                className={`absolute inset-y-0 ${
                  i18n.language === "ar" ? "right-3" : "left-3 "
                } h-5 w-5 text-gray-400 my-auto`}
              />
              <input
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                value={phrase}
                type="text"
                onChange={(e) => setPhrase(e.target.value)}
                className={`font-CairoMedium rounded-md ${
                  i18n.language === "ar" ? "pr-10" : "pl-10"
                }  py-3 w-full placeholder:text-gray-400 outline-none`}
                placeholder={
                  i18n.language === "ar"
                    ? "ابحث عن العلامة التجارية وابحث عن البديل لها"
                    : "Find the brand and find an alternative to it."
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search_data();
                  }
                }}
              />
              {phrase ? (
                <XCircleIcon
                  onClick={() => {
                    setPhrase("");
                    setisEmptySearchResult(false);
                    get_all_boycott_data();
                  }}
                  className={`absolute inset-y-0 ${
                    i18n.language === "ar" ? "left-3" : "right-3"
                  } h-5 w-5 text-primary-red my-auto`}
                />
              ) : null}
            </div>
            <button
              onClick={() => {
                if (phrase) search_data();
              }}
              className={`${
                phrase ? "bg-[#027b3e]" : "bg-gray-400 cursor-not-allowed	"
              } text-white py-2 px-10 lg:py-0 lg:py-3 rounded-md mx-auto lg:mx-0 font-CairoMedium`}
            >
              {t("search")}
            </button>
          </div>
        </div>

        {data.length > 0 ? (
          <div className="flex justify-center  ">
            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-7  mx-auto">
              <div className="bg-[#F9F9F9] w-64 py-3 rounded-lg border border-gray-200 text-center font-semibold text-xl mt-4">
                <p className="text-primary-green">
                  {searchCounter?.toLocaleString()}
                </p>
                <h3 className="text-lg font-CairoMedium">
                  {t("numberOfSearches")}
                </h3>
              </div>

              <div className="bg-[#F9F9F9]  w-64 py-3 rounded-lg border border-gray-200 text-center font-semibold text-xl mt-4">
                <p className="text-primary-green">
                  {visitCounter?.toLocaleString()}
                </p>
                <h3 className="text-lg font-CairoMedium">
                  {t("numberOfVisits")}
                </h3>
              </div>
            </div>
          </div>
        ) : null}

        {data.length > 0 && !isEmptySearchResult ? (
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 my-10">
            {data.map((el) => {
              return (
                <div
                  key={el._id}
                  className="border border-gray-200 rounded-lg "
                >
                  <div className="flex m-3 ">
                    <img
                      src={el.logo}
                      alt={el.name}
                      className="h-24 w-20 object-contain"
                    />
                    <div className="my-auto space-y-1 ml-5">
                      <div className="text-sm text-primary-green">
                        {el.mother_company.motherCompanyName}
                      </div>
                      <div className="uppercase text-base font-semibold">
                        {el.name_en}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-evenly py-3 bg-[#F9F9F9] rounded-b-lg">
                    <h3
                      onClick={() => {
                        window.open(el.mother_company.Proof);
                      }}
                      className="font-CairoMedium px-3 py-1 rounded-full text-primary-green underline cursor-pointer"
                    >
                      {t("proof")}
                    </h3>

                    <h3
                      onClick={() =>
                        navigate(`/alternatives`, {
                          state: {
                            motherCompanyName:
                              el.mother_company.motherCompanyName,
                            name: el.name_en,
                            id: el._id,
                          },
                        })
                      }
                      className="font-CairoMedium border px-5 py-1 border-primary-green rounded-full bg-white text-primary-green cursor-pointer"
                    >
                      {t("searchForAlt")}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        ) : isEmptySearchResult ? (
          <div dir="rtl" className="my-10 text-lg font-CairoMedium">
            <p className="font-semibold">{t("noResultFound")}</p>

            <p className="text-gray-500">
              {t("DoYouHaveReport")}

              <label
                onClick={() => navigate("/boycott-form")}
                className="underline text-indigo-600 cursor-pointer"
              >
                {t("link")}
              </label>
            </p>
          </div>
        ) : (
          <div className="my-20">
            <Loader />
          </div>
        )}
      </div>

      <div className="my-5">
        <ReactPaginate
          previousLabel="<"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={Math.ceil(total / limit)}
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </Layout>
  );
}
