import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Layout from "../components/Layout";
import { BASE_URL } from "../config/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { ReactComponent as DirectionIcon } from "../assets/icons/DirectionIcon.svg";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Verified() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [visitCounter, setvisitCounter] = useState(0);
  const [isExist, setisExist] = useState(false);

  function open_new_tab(item) {
    let url = `https://www.google.com/maps/search/?api=1&query=${item}`;
    window.open(url);
  }

  function check_brand_verification() {
    axios
      .get(
        `${BASE_URL}/verify/brand/${location.state.brandId}/alternative/${location.state.AltName}`
      )
      .then((res) => {
        setvisitCounter(res.data.stats.visits_counter);
        setisExist(true);
      })
      .catch((err) => {
        setisExist(false);
      });
  }

  useEffect(() => {
    check_brand_verification();
  }, []);

  return (
    <Layout>
      {isExist ? (
        <div className="my-20 bg-gray-100 border border-primary-green rounded-3xl p-5 shadow">
          <CheckBadgeIcon className="h-12 w-12 text-green-700 mx-auto" />
          <h3 className="font-CairoSemiBold text-primary-green text-center text-2xl font-bold mb-5">
            {t("thisCompany")}
            <label className="mx-1 text-[#A81427]">{t("doesNot")}</label>
            {t("supportTheZionistEntity")}
          </h3>

          <img
            alt={location.state.alternative.name}
            src={location.state.alternative.logo}
            className="mx-auto h-24 w-24 object-contain mb-5 cursor-pointer"
          />

          <h2 className={`text-3xl text-center font-bold uppercase`}>
            {location.state.AltName}
          </h2>

          <div className="flex justify-center py-3  rounded-b-lg">
            <DirectionIcon className="h-5 w-5 my-auto" />
            <h3
              onClick={() => {
                open_new_tab(location.state.AltName);
              }}
              className="font-CairoMedium p-1 rounded-full underline cursor-pointer text-base text-primary-green cursor-pointer"
            >
              {t("GetDirections")}
            </h3>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-7 mb-5 mx-auto">
              <div className="bg-[#F9F9F9] px-16 py-3 rounded-xl border border-gray-200 text-center font-semibold text-xl mt-4">
                <h3 className="font-semibold my-3 font-CairoMedium">
                  {t("NumberOfPostsForThisCompany")}
                </h3>
                <p className="text-primary-green text-2xl">{visitCounter}</p>
              </div>
            </div>
          </div>
          <h3
            onClick={() => {
              navigate("/");
            }}
            className="font-CairoMedium text-center p-1 rounded-full underline cursor-pointer text-lg text-primary-green cursor-pointer"
          >
            {t("BackToMainPage")}
          </h3>
        </div>
      ) : (
        <div className="font-CairoMedium text-primary-red font-bold text-center text-2xl my-20">
          {t("ThereIsNoInformation")}
          <br />
          <h3
            onClick={() => {
              navigate("/");
            }}
            className="text-center p-1 rounded-full underline cursor-pointer text-lg text-primary-green cursor-pointer"
          >
            {t("BackToMainPage")}
          </h3>
        </div>
      )}
      <ToastContainer />
    </Layout>
  );
}
