import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Layout from "../components/Layout";
import { BASE_URL } from "../config/constants";
import Loader from "../components/Loader";
import { ReactComponent as DirectionIcon } from "../assets/icons/DirectionIcon.svg";
import {
  ArrowRightIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";

export default function Alt() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const countries = [
    {
      name: "Jordan",
      name_ar: "الأردن",
    },
    {
      name: "Saudi",
      name_ar: "السعودية",
    },
    {
      name: "Egypt",
      name_ar: "مصر",
    },
    {
      name: "Qatar",
      name_ar: "قطر",
    },
  ];
  const [data, setdata] = useState([]);
  const [isOpenFilter, setisOpenFilter] = useState(false);
  const [selectedCountry, setselectedCountry] = useState("");

  const [emptyErrMsg, setemptyErrMsg] = useState(false);

  function get_brand_alt() {
    axios
      .get(`${BASE_URL}/sub_companies/${location.state.id}/alternatives`, {
        params: {
          country: selectedCountry,
        },
      })
      .then((res) => {
        if (res.data.length <= 0 && selectedCountry !== "") {
          setemptyErrMsg(t("newAltWillBeAdded"));
        } else {
          setemptyErrMsg("");
        }
        setdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function open_new_tab(item) {
    let url = `https://www.google.com/maps/search/?api=1&query=${item}`;
    window.open(url);
  }

  useEffect(() => {
    get_brand_alt();
  }, [selectedCountry]);

  return (
    <Layout>
      <div className="max-w-7xl justify-center mx-auto p-5">
        <div
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          className="flex flex-wrap  mb-5"
        >
          <div
            onClick={() => setisOpenFilter(!isOpenFilter)}
            className="flex bg-primary-green cursor-pointer py-2 px-4 text-white space-x-1 shadow rounded-full"
          >
            <AdjustmentsHorizontalIcon className="h-6 w-6 my-auto" />

            <div className="font-CairoMedium text-sm my-auto">
              {t("chooseCountry")}
            </div>
          </div>

          {isOpenFilter ? (
            <div className="flex  overflow-auto py-3 lg:py-0.5">
              {countries.map((country, idx) => {
                return (
                  <div
                    onClick={() => {
                      if (selectedCountry === country.name) {
                        setselectedCountry("");
                      } else {
                        setselectedCountry(country.name);
                      }
                    }}
                    key={idx}
                    className={`flex font-CairoMedium py-2 px-5 mx-2 ${
                      selectedCountry === country.name
                        ? "text-white bg-primary-green"
                        : "bg-white text-primary-green"
                    }  cursor-pointer shadow rounded-full `}
                  >
                    {i18n.language === "ar" ? country.name_ar : country.name}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <h3
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            className="font-CairoMedium text-lg"
          >
            {t("AlternativesFor")}
            <label className="text-primary-red mx-1 uppercase font-semibold">
              {location.state.name}
            </label>
          </h3>

          {/* GRID */}
          {emptyErrMsg ? (
            <div className="font-CairoMedium text-lg text-right my-3">
              {emptyErrMsg}
            </div>
          ) : (
            <>
              {data.length > 0 ? (
                data[0].name === "" && data[0].logo === "" ? (
                  <div
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    className="my-10 text-lg font-CairoMedium"
                  >
                    <p className="font-semibold">{t("NoProductsAddedYet")}</p>

                    <p className="text-gray-500">
                      {t("doYouHaveRecommendedProducts")}{" "}
                      <label
                        onClick={() => navigate("/boycott-form")}
                        className="underline text-indigo-600 cursor-pointer"
                      >
                        {t("link")}
                      </label>
                    </p>
                  </div>
                ) : (
                  <div className="my-7 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                    {data.map((el, idx) => {
                      return (
                        <div
                          key={el._id}
                          className="border border-gray-200 rounded-lg "
                        >
                          <div className=" p-2 bg-[#F9F9F9]">
                            <div className="flex justify-end">
                              <div
                                onClick={() => {
                                  navigate(`/verified`, {
                                    state: {
                                      brandId: location.state.id,
                                      AltName: el.name,
                                      alternative: el,
                                    },
                                  });
                                }}
                                className="bg-white rounded-full mx-2 shadow p-2 cursor-pointer"
                              >
                                <ArrowRightIcon className="h-5 w-5 " />
                              </div>
                            </div>

                            <div className="flex  ml-5">
                              <img
                                src={el.logo}
                                alt={el.name}
                                className="h-24 w-20 object-contain"
                              />
                              <div className="ml-3 my-auto uppercase text-base font-semibold">
                                {el.name}
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-center py-3  rounded-b-lg">
                            <DirectionIcon className="h-5 w-5 my-auto" />
                            <h3
                              onClick={() => {
                                open_new_tab(el.name);
                              }}
                              className="font-CairoMedium p-1 rounded-full underline cursor-pointer text-base text-primary-green cursor-pointer"
                            >
                              {t("GetDirections")}
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              ) : (
                <Loader />
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
