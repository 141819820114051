import { ReactComponent as ShareIcon } from "../assets/icons/ShareIcon.svg";

function FloatingButton({ setOpen }) {
  return (
    <div className="fixed bottom-5 right-5">
      <button
        onClick={() => {
          setOpen(true);
        }}
        className="bg-primary-green text-white p-3 rounded-full shadow-md outline-none"
      >
        <ShareIcon className="h-6 w-6" />
      </button>
    </div>
  );
}

export default FloatingButton;
