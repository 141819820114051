import axios from "axios";
import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { BASE_URL } from "../config/constants";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as User } from "../assets/icons/User.svg";
import { useTranslation } from "react-i18next";

export default function Feed() {
  const { t, i18n } = useTranslation();
  const [posts, setposts] = useState([]);
  const [data, setdata] = useState({});

  const types = [
    {
      name_ar: "اقتراح",
      name_en: "Suggestion",
    },
    {
      name_ar: "تحذير",
      name_en: "Caution",
    },
    {
      name_ar: "توعية",
      name_en: "Awareness",
    },
  ];

  const [activePage, setactivePage] = useState(0);
  // const [total, settotal] = useState(0);

  const fakeId = Math.floor(Math.random() * 999999999);

  function get_all_posts() {
    axios
      .get(`${BASE_URL}/posts?limit=100&page=${activePage}`)
      .then((res) => {
        // settotal(res.data.total);
        setposts(res.data.posts);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function create_post() {
    if (data.title && data.type && data.description) {
      axios
        .post(`${BASE_URL}/posts`, {
          ...data,
          user_id: `مستخدم${fakeId}`,
        })
        .then((res) => {
          setdata({
            title: "",
            description: "",
            type: "",
          });
          toast.success("تم نشر المحتوى بنجاح", {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          get_all_posts();
        })
        .catch((err) => {
          toast.error("Sorry, something went wrong please try again.", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("الرجاء تعبأة الحقول كلها", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  useEffect(() => {
    get_all_posts();
  }, []);

  return (
    <Layout>
      {/* CREATE POST */}
      <div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className="font-bold text-2xl font-CairoBold"
      >
        {t("ShareAndHelpOthers")}
      </div>
      <div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className="p-3 border bg-gray-50 rounded-lg"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-2">
          <div>
            <label className="font-CairoMedium block text-base font-bold leading-6 text-gray-900">
              {t("title")}
            </label>
            <div className="mt-2">
              <input
                value={data.title}
                id="title"
                name="title"
                type="text"
                onChange={(e) => {
                  setdata({ ...data, title: e.target.value });
                }}
                className="font-CairoMedium outline-none border px-4 block w-full rounded-md py-1.5 text-gray-900 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label className=" font-CairoMedium block text-base font-bold leading-6 text-gray-900">
              {t("ContentType")}{" "}
            </label>
            <select
              id="type"
              name="type"
              value={data.type}
              onChange={(e) => {
                setdata({ ...data, type: e.target.value });
              }}
              className="w-full p-0.5 px-2 border rounded-lg mt-2 font-CairoMedium"
            >
              <option value="">{t("ChooseTheContentType")}</option>
              {types.map((item, idx) => {
                return (
                  <option key={idx} value={item.name_ar}>
                    {i18n.language === "ar" ? item.name_ar : item.name_en}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="my-2">
          <label className="font-CairoMedium block text-base font-bold leading-6 text-gray-900">
            {t("Info")}
          </label>
          <div className="mt-2">
            <textarea
              id="description"
              name="description"
              value={data.description}
              rows={3}
              onChange={(e) => {
                setdata({ ...data, description: e.target.value });
              }}
              className="font-CairoMedium outline-none border px-4 block w-full rounded-md py-1.5 text-gray-900 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="flex">
          <button
            onClick={() => {
              create_post();
            }}
            className="font-CairoMedium bg-primary-green text-white px-20 py-1 rounded-lg mx-auto "
          >
            {t("Share")}
          </button>
        </div>
      </div>

      {/* VIEW POSTS */}
      <div className="my-10 max-w-3xl mx-auto">
        {posts.length > 0
          ? posts.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="bg-gray-50 border rounded-md p-4 mb-10"
                >
                  <div className="flex justify-between mb-2">
                    <div className="flex space-x-2 ">
                      <div className="my-auto bg-white p-2 rounded-full">
                        <User className="h-6 w-6 " />
                      </div>
                      <div>
                        <div className="font-CairoSemiBold my-auto">
                          @{item.user_id}
                        </div>
                        <div className="my-auto text-sm font-CairoRegular">
                          {moment(item.created_on).startOf("hour").fromNow()}
                        </div>
                        <div className="flex text-sm font-CairoRegular text-primary-green">
                          <MapPinIcon className="h-4 w-4 my-auto" />
                          {item.location}
                        </div>
                      </div>
                    </div>
                    <div
                      dir="rtl"
                      className={`font-CairoRegular border ${
                        item.type === "تحذير"
                          ? "border-primary-red text-primary-red"
                          : item.type === "توعية"
                          ? "border-yellow-600 text-yellow-600"
                          : "border-primary-green text-primary-green"
                      }  my-auto py-1 px-4 rounded-xl`}
                    >
                      #{item.type}
                    </div>
                  </div>
                  <div>
                    <div className="text-base font-CairoBold text-right mb-2 break-words">
                      {item.title}
                    </div>
                    <div className="text-right font-CairoMedium break-words">
                      {item.description}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <ToastContainer />
    </Layout>
  );
}
