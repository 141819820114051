import React, { useRef } from "react";
import { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } from "../config/constants";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";

const Form = () => {
  const form = useRef();
  const { t, i18n } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        SERVICE_ID, //YOUR_SERVICE_ID
        TEMPLATE_ID, //YOUR_TEMPLATE_ID
        form.current,
        PUBLIC_KEY //YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          form.current.reset();

          toast.success("شكرا جزيلا لمساعدتك", {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        (error) => {
          form.current.reset();
          toast.error("Sorry, something went wrong please try again.", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
  };

  return (
    <Layout>
      <div dir={i18n.language === "ar" ? "rtl" : "ltr"} className="p-4">
        <form ref={form} onSubmit={sendEmail} className="max-w-6xl mx-auto">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block font-CairoMedium text-gray-800 font-medium mb-2"
            >
              {t("BrandName")}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Starbucks"
              required
              // value={formData.name}
              // onChange={handleInputChange}
              className="w-full p-2 border rounded-lg font-CairoMedium"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="alternatives"
              className="block text-gray-800 font-medium mb-2 font-CairoMedium"
            >
              {t("Alternatives")}
            </label>
            <textarea
              id="alternatives"
              name="alternatives"
              placeholder="BLK coffee, Marouf, Almond الخ..."
              // value={formData.alternatives}
              // onChange={handleInputChange}
              className="w-full p-2 border rounded-lg font-CairoMedium"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="notes"
              className="block text-gray-800 font-medium mb-2 font-CairoMedium"
            >
              {t("Notes")}
            </label>
            <textarea
              id="notes"
              name="notes"
              placeholder={t("ShareYourFeedbackHere")}
              // value={formData.alternatives}
              // onChange={handleInputChange}
              className="w-full p-2 border rounded-lg font-CairoMedium"
            />
          </div>

          <div className="mb-4 flex justify-center mx-auto">
            <button
              type="submit"
              className="font-CairoMedium bg-[#027b3e] text-white py-2 px-20 rounded-lg "
            >
              {t("Send")}
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </Layout>
  );
};

export default Form;
