//library imports
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { ReactComponent as FacebookIcon } from "../assets/icons/Facebook.svg";
import { ReactComponent as WhatsAppIcon } from "../assets/icons/Whatsapp.svg";
import { ReactComponent as MailIcon } from "../assets/icons/Mail.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/Linkedin.svg";
import { ReactComponent as TelegramIcon } from "../assets/icons/Telegram.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/Twitter.svg";

export default function ShareModal({ open, setOpen, theme, shareDetails }) {
  const cancelButtonRef = useRef(null);
  const { backgroundColor, secondaryBackgroundColor, textColor, borderColor } =
    theme;
  const { title } = shareDetails;
  const fullShareLink = `https://qate3-israel.com`;

  const media = [
    {
      icon: (
        <WhatsappShareButton
          url={fullShareLink}
          title={`\n${title}\n`}
          className="outline-0"
        >
          <WhatsAppIcon className="h-12 w-12 " />
        </WhatsappShareButton>
      ),
      name: "واتساب",
    },
    {
      icon: (
        <FacebookShareButton
          url={fullShareLink}
          quote={`\n${title}\n`}
          hashtag={"#qate3-israel"}
          className="outline-0"
        >
          <FacebookIcon className="h-12 w-12 " />
        </FacebookShareButton>
      ),
      name: "فيسبوك",
    },
    {
      icon: (
        <TwitterShareButton
          url={fullShareLink}
          title={`\n${title}\n`}
          className="outline-0"
        >
          <TwitterIcon className="h-12 w-12 " />
        </TwitterShareButton>
      ),
      name: "تويتر",
    },
    {
      icon: (
        <TelegramShareButton
          url={fullShareLink}
          title={`\n${title}\n`}
          className="outline-0"
        >
          <TelegramIcon className="h-12 w-12 " />
        </TelegramShareButton>
      ),
      name: "تيليغرام",
    },
    {
      icon: (
        <LinkedinShareButton
          url={fullShareLink}
          title={`\n${title}\n`}
          className="outline-0"
        >
          <LinkedinIcon className="h-12 w-12 " />
        </LinkedinShareButton>
      ),
      name: "لينكدان",
    },
    {
      icon: (
        <EmailShareButton
          url={fullShareLink}
          title={`\n${title}\n`}
          className="outline-0"
        >
          <MailIcon className="h-12 w-12 " />
        </EmailShareButton>
      ),
      name: "إيميل",
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#A4A4A4] bg-opacity-60 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block rounded-xl px-3 lg:px-10 align-middle ${backgroundColor}  text-left overflow-hidden shadow-xl transform transition-all my-auto lg:max-w-xl w-full mx-1`}
            >
              <div dir="rtl" className="flex justify-between  my-7">
                <h3
                  className={`font-CairoMedium my-auto text-center text-xl  mr-1 ${textColor}`}
                >
                  شارك موقع{" "}
                  <label className="text-primary-red">qate3-israel</label>{" "}
                </h3>
                <XMarkIcon
                  className={`h-7 w-7 cursor-pointer `}
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className="flex justify-evenly my-7">
                {media.map((item, idx) => {
                  return (
                    <div key={idx} className="mx-auto">
                      {item.icon}
                    </div>
                  );
                })}
              </div>

              <div className="flex pt-3 pb-5">
                <div
                  className={` ${secondaryBackgroundColor} w-full px-4 py-2 rounded-md mx-3`}
                >
                  <input
                    value={fullShareLink}
                    readOnly
                    dir="ltr"
                    type="url"
                    className={`w-full ${secondaryBackgroundColor} mx-1 text-sm outline-none ${textColor}`}
                  />
                </div>
                <button
                  onClick={() => {
                    copy(fullShareLink);

                    setTimeout(() => {
                      setOpen(false);
                    }, 200);
                  }}
                  className="font-CairoMedium text-white bg-primary-green py-1 px-3 rounded-lg"
                >
                  نسخ
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
