import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GA_KEY } from "./config/constants";
import ReactGA from "react-ga4";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const selectedLanguage = localStorage.getItem("selectedLanguage");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: { translation: require("./assets/language/ar.json") },
      "en-US": { translation: require("./assets/language/en.json") },
    },
    fallbackLng: "ar", // Default language
    supportedLngs: ["ar", "en-US"],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    lng: selectedLanguage || "ar", // Use selected language if available, otherwise default to Arabic
  });

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("selectedLanguage", lng); // Save selected language to localStorage
});

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize(GA_KEY);

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
 });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
